<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";
import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Ajouter un client",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader /*MazPhoneNumberInput*/ },
  data() {
    return {
      title: "Ajouter un client",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      file: null,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      nom: "",
      prenom: "",
      ville: "",
      contact: "",
      email: "",
      designation: "",
      ifu: "",
      imageCode: "",
      estEntreprise: false,
      filePhoto: null,
    };
  },

  async mounted() {},

  methods: {
    async addClient() {
      const client = await apiRequest("POST", "admin/entreprise/client", {
        nom: this.nom,
        prenom: this.prenom,
        ville: this.ville,
        contact: this.contact,
        email: this.email,
        designation: this.designation,
        ifu: this.ifu,
        imageCode: "",
        estEntreprise: this.estEntreprise,
      });
      if (client && client.data) {
        //console.log("client", client.data);
        let leClient = client.data.client;
        this.addPhoto((leClient.id).toString());
        this.positionAdd();
        router.push(`/client/add`);
      } else {
        console.log("erreur");
      }
    },
    handleFile() {
      this.filePhoto = this.$refs.filePhoto.files[0];
    },
    async addPhoto(idClient) {
      if (this.filePhoto) {
        let formData = new FormData();
        formData.append("idClient", idClient);
        formData.append("pic", this.filePhoto);
        const returnfile = await apiRequest(
          "POST",
          "admin/entreprise/client-profil",
          formData,
          true
        );
        if (returnfile && returnfile.data) {
          return true;
        }
      }
    },
    
    

    positionAdd() {
      Vue.swal({
        positionAdd: "center-end",
        icon: "success",
        title: "Ajout effectué avec succès",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée avec succès!!",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" @submit.prevent="formSubmit">
              <div class="mt-2">
                <div class="row ">
                  <div class="col-md-1">
                    <label for="check"
                      >Entreprise</label
                    >
                    </div>
                    <div class="col-md-1">
                    <input
                      type="checkbox"
                      v-model="estEntreprise"
                      class="form-control"
                      id="check"
                    />
                  </div>
                </div>
                <div class="row mt-4" v-if="!estEntreprise">
                  <div class="col-md-6">
                    <label for="">Nom</label>
                    <input type="text" class="form-control" v-model="nom" />
                  </div>
                  <div class="col-md-6">
                    <label for="">Prénom</label>
                    <input type="text" class="form-control" v-model="prenom" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="">Ville de résidence</label>
                    <input type="text" class="form-control" v-model="ville" />
                  </div>
                  <div class="col-md-6">
                    <label for="">Contact</label>
                    <input type="text" class="form-control" v-model="contact" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="">Email</label>
                    <input type="email" class="form-control" v-model="email" />
                  </div>
                  <div class="col-md-6">
                    <label for="">Image</label>
                    <input type="file"
                    id="filePhoto"
                    ref="filePhoto"
                    v-on:change="handleFile"
                    class="form-control"
                    placeholder="Photo" />
                  </div>
                </div>
                <div class="row mt-4" v-if="estEntreprise">
                  <div class="col-md-6">
                    <label for="">Désignation</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="designation"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="">IFU</label>
                    <input type="text" class="form-control" v-model="ifu" />
                  </div>
                </div>
                <div class="float-right mt-4">
                  <button class="btn btn-primary" @click="addClient">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
